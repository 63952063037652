import { useState, useRef, useEffect } from 'react';
import { Calendar, ChevronLeft, ChevronRight, ChevronDown } from 'lucide-react';

const DatePicker = ({ value, onChange, tabIndex = 0 }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [showMonthSelect, setShowMonthSelect] = useState(false);
  const [showYearSelect, setShowYearSelect] = useState(false);
  const datePickerRef = useRef(null);
  const selectedDate = value ? new Date(value) : null;
  const today = new Date();

  const months = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];

  // Generate past years only (100 years in the past up to current year)
  const currentYear = today.getFullYear();
  const years = Array.from({ length: 100 }, (_, i) => currentYear - 99 + i).reverse();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (datePickerRef.current && !datePickerRef.current.contains(event.target)) {
        setIsOpen(false);
        setShowMonthSelect(false);
        setShowYearSelect(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Escape') {
        setIsOpen(false);
        setShowMonthSelect(false);
        setShowYearSelect(false);
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, []);

  const getDaysInMonth = (date) => {
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    const days = [];
    
    for (let i = 0; i < firstDay.getDay(); i++) {
      days.push(null);
    }
    
    for (let i = 1; i <= lastDay.getDate(); i++) {
      days.push(new Date(date.getFullYear(), date.getMonth(), i));
    }
    
    return days;
  };

  const formatDate = (date) => {
    if (!date) return '';
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  const isSameDay = (date1, date2) => {
    if (!date1 || !date2) return false;
    return (
      date1.getDate() === date2.getDate() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getFullYear() === date2.getFullYear()
    );
  };

  const isToday = (date) => {
    return isSameDay(date, today);
  };

  const isFutureDate = (date) => {
    return date > today;
  };

  const handleMonthSelect = (monthIndex) => {
    const newDate = new Date(currentMonth.getFullYear(), monthIndex, 1);
    if (newDate <= today) {
      setCurrentMonth(newDate);
    }
    setShowMonthSelect(false);
  };

  const handleYearSelect = (year) => {
    const newDate = new Date(year, currentMonth.getMonth(), 1);
    if (newDate <= today) {
      setCurrentMonth(newDate);
    }
    setShowYearSelect(false);
  };

  const handleDateSelect = (date) => {
    if (!isFutureDate(date)) {
      // Adjust for timezone offset
      const adjustedDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
      onChange({ target: { value: adjustedDate.toISOString().split('T')[0] } });
      setIsOpen(false);
    }
  };

  // Filter available months in current year
  const availableMonths = months.filter((_, index) => {
    if (currentMonth.getFullYear() === today.getFullYear()) {
      return index <= today.getMonth();
    }
    return true;
  });

  return (
    <div className="relative" ref={datePickerRef}>
      <div
        onMouseDown={(e) => {
          e.preventDefault(); // Prevent default to avoid immediate blur
          e.stopPropagation(); // Prevent event bubbling
          console.log('DatePicker clicked, toggling open state');
          setIsOpen((prev) => !prev);
        }}
        onFocus={() => {
          console.log('DatePicker focused');
          setIsOpen(true);
        }}
        onBlur={(e) => {
          console.log('DatePicker blur event');
          setTimeout(() => {
            if (!datePickerRef.current?.contains(e.relatedTarget)) {
              console.log('Closing DatePicker due to blur');
              setIsOpen(false);
            }
          }, 100); // Slight delay to allow focus to settle
        }}
        tabIndex={0}
        className="w-full h-12 px-4 py-2 bg-white border rounded-lg shadow-sm cursor-pointer hover:border-gray-400 flex items-center justify-between"
      >
        <span className={value ? "text-gray-900" : "text-gray-500"}>
          {value ? formatDate(new Date(value)) : "Date of Birth"}
        </span>
        <div className="flex items-center">
          <Calendar size={20} className="text-gray-400" />
        </div>
      </div>

      {isOpen && (
        <div className="absolute z-50 mt-2 bg-white rounded-lg shadow-lg border p-4 w-full sm:w-[320px]">
          <div className="flex items-center justify-between mb-4">
            <button
              onClick={() => setShowMonthSelect(!showMonthSelect)}
              className="text-lg font-semibold px-2 py-1 hover:bg-gray-100 rounded-md flex items-center"
            >
              {currentMonth.toLocaleDateString('en-US', { month: 'long' })}
              <ChevronDown className="w-4 h-4 ml-1" />
            </button>
            <button
              onClick={() => setShowYearSelect(!showYearSelect)}
              className="text-lg font-semibold px-2 py-1 hover:bg-gray-100 rounded-md flex items-center"
            >
              {currentMonth.getFullYear()}
              <ChevronDown className="w-4 h-4 ml-1" />
            </button>
          </div>

          {showMonthSelect && (
            <div className="absolute z-20 top-16 left-4 bg-white border rounded-lg shadow-lg p-2 w-40 max-h-64 overflow-y-auto">
              {availableMonths.map((month, index) => (
                <button
                  key={month}
                  onClick={() => handleMonthSelect(index)}
                  className={`w-full text-left px-3 py-2 rounded-md ${
                    currentMonth.getMonth() === index
                      ? 'bg-gray-100 font-medium'
                      : 'hover:bg-gray-50'
                  }`}
                >
                  {month}
                </button>
              ))}
            </div>
          )}

          {showYearSelect && (
            <div className="absolute z-20 top-16 right-4 bg-white border rounded-lg shadow-lg p-2 w-24 max-h-64 overflow-y-auto">
              {years.map(year => (
                <button
                  key={year}
                  onClick={() => handleYearSelect(year)}
                  className={`w-full text-left px-3 py-2 rounded-md ${
                    currentMonth.getFullYear() === year
                      ? 'bg-gray-100 font-medium'
                      : 'hover:bg-gray-50'
                  }`}
                >
                  {year}
                </button>
              ))}
            </div>
          )}

          <div className="grid grid-cols-7 mb-2">
            {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map(day => (
              <div key={day} className="text-center text-sm font-medium text-gray-600">
                {day}
              </div>
            ))}
          </div>

          <div className="grid grid-cols-7 gap-1">
            {getDaysInMonth(currentMonth).map((date, index) => (
              <div
                key={index}
                className="aspect-square flex items-center justify-center"
              >
                {date && (
                  <button
                    onClick={() => handleDateSelect(date)}
                    disabled={isFutureDate(date)}
                    className={`w-10 h-10 rounded-full flex items-center justify-center text-sm
                      ${isFutureDate(date)
                        ? 'text-gray-300 cursor-not-allowed'
                        : isSameDay(date, selectedDate)
                        ? 'bg-gray-900 text-white hover:bg-gray-800'
                        : isToday(date)
                        ? 'bg-gray-100 text-gray-900 hover:bg-gray-200'
                        : 'hover:bg-gray-100 text-gray-700'
                      }`}
                  >
                    {date.getDate()}
                  </button>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default DatePicker;