import React, { useState, useEffect, useRef } from 'react';
import { ChevronDown, ChevronUp, Bell, Printer } from 'lucide-react';
import { Card, CardHeader, CardTitle, CardContent } from '../components/ui/card';
import toast from 'react-hot-toast';
import { saveMonitoredEntity } from '../services/api';
import { auth } from '../config/firebase';
import MonitorButton from './MonitorButton';
import { COUNTRY_MAPPING } from '../constants/countries';
import { db } from '../config/firebase';
import { collection, getDocs } from 'firebase/firestore';
import { 
  formatDate, 
  getRiskTag, 
  getResultTitle, 
  formatLLMResponse, 
  renderLinks, 
  getCountryCode 
} from '../utils/formatters';

const MAX_MONITORED_ENTITIES = 5;

const SearchResults = ({ 
  results = [], 
  formData, 
  isMonitoring = false,
  onNewSearch 
}) => {
    const [expanded, setExpanded] = useState({});
    const [comments, setComments] = useState({});
    const [sectionExpanded, setSectionExpanded] = useState({});
  
    useEffect(() => {
      // Only run if we have actual search results (not just an empty array from initial state)
      if (results?.length > 0 && results[0]?.id) {
        // If there's only one result, expand it by default
        if (results.length === 1) {
          setExpanded({ [results[0].id]: true });
        } else {
          setExpanded({});
        }
        setComments({});
        setSectionExpanded(prevState => {
          const newState = {};
          results.forEach(result => {
            if (result) {
              const resultId = result.id || Math.random().toString();
              newState[resultId] = { ...prevState[resultId], media: true };
            }
          });
          return newState;
        });
        localStorage.removeItem('investigatorConclusion');
      }
    }, [results]); // Only depend on results
  
    // Only render if we have actual search results
    if (!results?.length || !results[0]?.id) {
      return null;
    }

    // Near the top of the component, add this debug log
    // console.log('Search Results:', results.map(result => ({
    //   id: result.id,
    //   name: result.name,
    //   caption: result.caption,
    //   formattedTitle: getResultTitle(result),
    //   rawResult: result // Include the full result for inspection
    // })));

    const getCountryName = (code) => {
      if (!code) return '';
      if (Array.isArray(code)) {
        return code.map(c => COUNTRY_MAPPING[c.toLowerCase()] || c).join(', ');
      }
      return COUNTRY_MAPPING[code.toLowerCase()] || code;
    };
  
    const handleCommentsChange = (resultId, newComment) => {
      setComments(prev => ({
        ...prev,
        [resultId]: newComment
      }));
    };
  
    const toggleSection = (resultId, section) => {
      setSectionExpanded(prev => ({
        ...prev,
        [resultId]: {
          ...(prev[resultId] || {}),
          [section]: !(prev[resultId]?.[section])
        }
      }));
    };
  
    const renderSectionWithToggle = (resultId, title, content, section) => {
      if (!content) return null;
      const isExpanded = sectionExpanded[resultId]?.[section];
    
      // Get badge styles - all classes must be statically defined for Tailwind
      const getBadgeStyles = (section) => {
        const badgeStyles = {
          sanctions: "bg-red-100 text-red-800",
          owners: "bg-blue-100 text-blue-800",
          directors: "bg-green-100 text-green-800",
          assets: "bg-purple-100 text-purple-800",
          links: "bg-orange-100 text-orange-800",
          associates: "bg-indigo-100 text-indigo-800",
          family: "bg-pink-100 text-pink-800",
          media: "bg-cyan-100 text-cyan-800"
        };
        return badgeStyles[section] || "bg-gray-100 text-gray-800";
      };
    
      // Get count based on content structure
      let count = 0;
      if (section === 'media') {
        count = content?.props?.children?.[0]?.props?.children?.length || 0;
      } else if (Array.isArray(content)) {
        count = content.length;
      }
    
      return (
        <div className="mt-4 border rounded-lg shadow-sm overflow-hidden">
          <button
            onClick={() => toggleSection(resultId, section)}
            className="w-full px-4 py-3 bg-gray-50 hover:bg-gray-100 transition-colors duration-200 flex justify-between items-center group"
          >
            <div className="flex items-center space-x-3">
              <span className="text-lg font-semibold text-gray-900">{title}</span>
              {count > 0 && (
                <span className={`inline-flex items-center justify-center px-2.5 py-0.5 rounded-full text-sm font-medium ${getBadgeStyles(section)}`}>
                  {count}
                </span>
              )}
            </div>
            {isExpanded ? 
              <ChevronUp className="h-5 w-5 text-gray-500 group-hover:text-gray-700" /> : 
              <ChevronDown className="h-5 w-5 text-gray-500 group-hover:text-gray-700" />
            }
          </button>
          {isExpanded && <div className="p-4 border-t">{content}</div>}
        </div>
      );
    };
  
    const renderEntityBox = ({ name, sourceUrl, riskTopic, idName, idNumber, relationship, country, date, additionalFields = {} }) => (
      <div className="border rounded-lg p-4 mb-4 bg-white shadow-sm">
        <h4 className="text-lg font-medium mb-2">
          {sourceUrl ? renderLinks([sourceUrl], name) : name}
        </h4>
        {riskTopic && <p className="mb-1"><strong>Risk:</strong> {getRiskTag(riskTopic)}</p>}
        {idName && idNumber && <p className="mb-1"><strong>{idName}:</strong> {idNumber}</p>}
        {relationship && <p className="mb-1"><strong>Relationship:</strong> {relationship}</p>}
        {country && <p className="mb-1"><strong>Country:</strong> {getCountryName(country)}</p>}
        {date && <p className="mb-1"><strong>Date:</strong> {formatDate(date)}</p>}
        {Object.entries(additionalFields).map(([key, value]) => 
          value && <p key={key} className="mb-1"><strong>{key}:</strong> {value}</p>
        )}
      </div>
    );
  
    // Define the renderBasicFields function
    const renderBasicFields = (properties) => {
      if (!properties) return null;
  
      // Helper function to create hyperlinks with better wrapping
      const createHyperlink = (value, type = 'url') => {
        if (!value) return null;
        
        if (type === 'email') {
          return <a href={`mailto:${value}`} className="text-blue-600 hover:text-blue-800 hover:underline break-all">{value}</a>;
        }
        
        return <a href={value} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:text-blue-800 hover:underline break-all">{value}</a>;
      };
  
      // Helper function to handle arrays of links with proper wrapping
      const formatLinks = (values) => {
        if (!Array.isArray(values)) values = [values];
        return values.map((value, index) => (
          <div key={index} className="break-all">
            {createHyperlink(value)}
          </div>
        ));
      };
  
      const fieldDefinitions = [
        { label: "Type", key: "schema" },
        { label: "Name", key: "name", format: arr => Array.isArray(arr) ? arr.join(", ") : arr },
        { label: "Birthday", key: "birthDate", format: formatDate },
        { label: "Legal Form", key: "legalForm", format: arr => Array.isArray(arr) ? arr.join(", ") : arr },
        { label: "Parent Company", key: "parent", format: arr => Array.isArray(arr) ? arr.join(", ") : arr },
        { label: "Incorporation Date", key: "incorporationDate", format: formatDate },
        { label: "Dissolution Date", key: "dissolutionDate", format: formatDate },
        { label: "Place of birth", key: "birthPlace" },
        { label: "Gender", key: "gender", format: arr => {
          const genderEmojis = {
            male: '👨',
            female: '👩',
            other: '🧑'
          };
          
          if (!arr) return '';
          const genders = Array.isArray(arr) ? arr : [arr];
          return genders.map(g => {
            const gender = g.toLowerCase();
            const emoji = genderEmojis[gender] || '🧑';
            return `${emoji} ${g.charAt(0).toUpperCase() + g.slice(1)}`;
          }).join(", ");
        }},
        { label: "Nationality", key: "nationality", format: getCountryName },
        { label: "Country", key: "country", format: getCountryName },
        { label: "Citizenship", key: "citizenship", format: getCountryName },
        { label: "Jurisdiction", key: "jurisdiction", format: getCountryName },
        { label: "Passport Number", key: "passportNumber" },
        { label: "Tax Number", key: "taxNumber" },
        { label: "VAT Number", key: "vatCode" },
        { label: "Address", key: "address" },
        { label: "Position", key: "position" },
        { label: "Education", key: "education" },
        { label: "Email", key: "email", format: arr => Array.isArray(arr) ? arr.map((email, i) => (
          <span key={i}>
            {i > 0 && ", "}
            {createHyperlink(email, 'email')}
          </span>
        )) : createHyperlink(arr, 'email') },
        { label: "Website", key: "website", format: formatLinks },
        { label: "SWIFT/BIC", key: "swiftBic" },
        { label: "LEI", key: "leiCode" },
        { label: "OpenCorporates URL", key: "opencorporatesUrl", format: formatLinks },
        { label: "ID Number", key: "idNumber" },
        { label: "INN", key: "innCode" },
        { label: "KPP", key: "kppCode" },
        { label: "OGRN", key: "ogrnCode" },
        { label: "PermID", key: "permId" },
        { label: "Registration Number", key: "registrationNumber" },
        { label: "Sector", key: "sector" },
        { label: "Status", key: "status" },
        { label: "Last change", key: "last_change", format: formatDate },
        { label: "Last processed", key: "last_seen", format: formatDate },
        { label: "First seen", key: "first_seen", format: formatDate },
        { label: "Sources", key: "sourceUrl", format: formatLinks }
      ];
  
      return (
        <div className="space-y-4">
          {fieldDefinitions.map(({ label, key, format }) => {
            const value = properties[key];
            if (!value || (Array.isArray(value) && value.length === 0)) return null;
  
            const displayValue = format ? format(value) : 
              Array.isArray(value) ? value.join(", ") : value;
  
            return (
              <div key={key} className="flex flex-col">
                <span className="font-medium text-gray-700">{label}</span>
                <span className="text-gray-900">{displayValue}</span>
              </div>
            );
          })}
        </div>
      );
    };
  
    // Add the renderSanctions function inside the SearchResults component
    const renderSanctions = (result) => {

      // console.log('Rendering sanctions for result:', {
      //   resultId: result.id,
      //   hasSanctions: !!result?.properties?.sanctions,
      //   sanctionsLength: result?.properties?.sanctions?.length,
      //   sanctionsSources: result?.sanctionsSources,
      //   fullResult: result
      // });
  
      if (!result?.properties?.sanctions?.length) {
        return null;
      }
  
      const sanctions = result.properties.sanctions;
      const sources = result.sanctionsSources || [];
  
      return (
        <div className="space-y-4">
          {sanctions.map((sanction, idx) => {
            if (!sanction?.datasets?.[0]) return null;
  
            const df = sources.find(source => 
              source.identifier === sanction.datasets[0]
            );
            
            if (!df) return null;
  
            const startDate = sanction.properties?.startDate?.[0];
            const formattedStartDate = startDate ? 
              new Date(startDate).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric'
              }) : 'NA';
  
            return (
              <div key={idx} className="p-4 bg-gray-50 rounded-lg">
                <h4 className="text-md font-medium text-gray-900 mb-2">
                  <a 
                    href={df.source_url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-600 hover:text-blue-800"
                  >
                    {df.title}
                  </a>
                </h4>
                <p><strong>Publisher:</strong> {df.publisher}</p>
                <p><strong>Publisher Country:</strong> {df.publisher_country}</p>
                <p><strong>Coverage Frequency:</strong> {
                  df.coverage_frequency ? 
                    df.coverage_frequency.charAt(0).toUpperCase() + 
                    df.coverage_frequency.slice(1) : 
                    'NA'
                }</p>
                <p><strong>Entity Sanctioned Since:</strong> {formattedStartDate}</p>
              </div>
            );
          })}
        </div>
      );
    };
  
    const handleMonitorClick = async (result) => {
      try {
        if (!formData) {
          toast.error("Missing search data");
          return;
        }

        // Add check for monitoring limit
        const monitoringRef = collection(db, 'users', auth.currentUser.uid, 'monitoring');
        const monitoringSnapshot = await getDocs(monitoringRef);
        
        if (monitoringSnapshot.size >= MAX_MONITORED_ENTITIES) {
          toast.error(`Maximum limit of ${MAX_MONITORED_ENTITIES} monitored entities reached`);
          return;
        }

        // Show immediate "Adding..." toast
        const toastId = toast.loading("Adding to monitoring...");

        // Get all results except the selected one and 'no_sanctions'
        const rejectedResults = results
          .filter(r => r.id !== result.id && r.id !== 'no_sanctions')
          .map(r => r.id);
    
        const monitoringData = {
          userId: auth.currentUser.uid,
          searchType: formData.searchType,
          individualName: formData.searchType === 'individual' ? result.caption : null,
          companyName: formData.searchType === 'company' ? result.caption : null,
          organization: formData.organization || null,
          designation: formData.designation || null,
          country: formData.country,
          matchThreshold: formData.matchThreshold / 100,
          frequency: 'daily',
          timestamp: new Date(),
          lastChecked: result.searchTimestamp || new Date(),
          alerts: 0,
          status: 'active',
          selectedResultId: result.id,
          results: [result],
          rejectedResults: rejectedResults
        };
    
        console.log('About to save monitoring data:', monitoringData);
    
        await saveMonitoredEntity(auth.currentUser.uid, monitoringData);
    
        // Update to success when complete
        toast.success("Entity added to monitoring", { id: toastId });

      } catch (error) {
        console.error('Error setting up monitoring:', error);
        toast.error('Failed to set up monitoring');
      }
    };

    // Deduplicate results based on ID
    const deduplicatedResults = results.filter((result, index, self) =>
      index === self.findIndex((r) => r.id === result.id)
    );

    if (!Array.isArray(results) || results.length === 0) {
      return null;
    }
  
    return (
      <div className="space-y-4">
        {deduplicatedResults.map((result, index) => {
          if (!result) return null;
          const resultId = result.id || `result_${index}`;
          const props = result.properties || {};
  
          return (
            <div key={resultId} className="border rounded-lg bg-white shadow-sm overflow-hidden">
              <div 
                className="px-3 sm:px-4 py-3 bg-gray-50 cursor-pointer"
                onClick={() => setExpanded(prev => ({ ...prev, [resultId]: !prev[resultId] }))}
              >
                <div className="flex justify-between items-center gap-4">
                  {/* Left side with name and tags */}
                  <div className="flex flex-col min-w-0">
                    {/* Name */}
                    <h3 className="text-base sm:text-lg font-medium text-gray-900 flex-shrink-0">
                      {getResultTitle(result)}
                    </h3>
                    {/* Tags */}
                    {!expanded[resultId] && result?.properties?.topics && (
                      <div className="flex flex-wrap gap-1.5 overflow-hidden mt-1">
                        {(() => {
                          const sanctionsTopics = result.properties.topics
                            .filter(t => t.startsWith('sanction'));
                          const otherTopics = result.properties.topics
                            .filter(t => !t.startsWith('sanction'));
                          
                          return [...sanctionsTopics, ...otherTopics].map((topic, idx) => (
                            <span 
                              key={idx}
                              className="inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800 flex-shrink-0 whitespace-nowrap overflow-hidden text-ellipsis"
                            >
                              {getRiskTag(topic)}
                            </span>
                          ));
                        })()}
                      </div>
                    )}
                  </div>

                  {/* Right side */}
                  <div className="flex items-center gap-2 sm:gap-4 flex-shrink-0">
                    {typeof result.score === 'number' && result.score > 0 && (
                      <span className="text-sm text-gray-500 whitespace-nowrap">
                        <span className="hidden sm:inline">Match Score: </span>
                        {Math.round(result.score * 100)}%
                      </span>
                    )}
                    {!isMonitoring && (
                      <div onClick={(e) => e.stopPropagation()}>
                        <MonitorButton onClick={() => handleMonitorClick(result)} />
                      </div>
                    )}
                    <div className="p-1 hover:bg-gray-200 rounded-full flex-shrink-0">
                      {expanded[resultId] ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
                    </div>
                  </div>
                </div>
              </div>
  
              {expanded[resultId] && (
                <div className="p-4">
                  {/* Risk Tags */}
                  {props.topics && Array.isArray(props.topics) && (
                    <div className="flex flex-wrap gap-2 mb-4">
                      {props.topics.map((topic, idx) => (
                        <span 
                          key={idx}
                          className="px-2 py-1 text-xs font-medium bg-red-100 text-red-800 rounded-full"
                        >
                          {getRiskTag(topic)}
                        </span>
                      ))}
                    </div>
                  )}
  
                  {/* Basic Fields - Pass the full result object instead of just properties */}
                  {renderBasicFields({
                    ...props,
                    first_seen: result.first_seen,
                    last_seen: result.last_seen,
                    last_change: result.last_change,
                    notes: props.notes
                  })}
  
                  {/* Sanctions */}
                  {renderSectionWithToggle(
                    resultId,
                    'Sanctions',
                    result?.properties?.sanctions?.length > 0 ? 
                      result.properties.sanctions.map((sanction, idx) => {
                        const source = result.sanctionsSources?.find(s => 
                          sanction.datasets?.[0] === s.identifier
                        );
                        
                        if (!source) return null;

                        const startDate = sanction.properties?.startDate?.[0];
                        const formattedStartDate = startDate ? 
                          new Date(startDate).toLocaleDateString('en-US', {
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric'
                          }) : 'NA';

                        return (
                          <div key={idx} className="p-4 bg-gray-50 rounded-lg">
                            <h4 className="text-md font-medium text-gray-900 mb-2">
                              <a 
                                href={source.source_url}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-blue-600 hover:text-blue-800"
                              >
                                {source.title}
                              </a>
                            </h4>
                            <p><strong>Publisher:</strong> {source.publisher}</p>
                            <p><strong>Publisher Country:</strong> {source.publisher_country}</p>
                            <p><strong>Coverage Frequency:</strong> {
                              source.coverage_frequency ? 
                                source.coverage_frequency.charAt(0).toUpperCase() + 
                                source.coverage_frequency.slice(1) : 
                                'NA'
                            }</p>
                            <p><strong>Entity Sanctioned Since:</strong> {formattedStartDate}</p>
                          </div>
                        );
                      }) : null,
                    'sanctions',
                    result?.properties?.sanctions?.length || 0
                  )}
  
                  {/* Owners */}
                  {renderSectionWithToggle(
                    resultId,
                    'Owners',
                    props.ownershipAsset?.map((ownership, idx) => renderEntityBox({
                      key: idx,
                      name: ownership.properties?.owner?.[0]?.caption,
                      sourceUrl: ownership.properties?.owner?.[0]?.properties?.sourceUrl?.[0],
                      riskTopic: ownership.properties?.owner?.[0]?.properties?.topics?.[0],
                      additionalFields: {
                        'Ownership': `${ownership.properties?.percentage || 0}%`,
                        'Start Date': formatDate(ownership.properties?.startDate)
                      }
                    })),
                    'owners',
                    result?.properties?.ownershipAsset?.length || 0
                  )}
  
                  {/* Directors */}
                  {renderSectionWithToggle(
                    resultId,
                    'Directors',
                    props.directorshipOrganization?.map((dir, idx) => renderEntityBox({
                      key: idx,
                      name: dir.properties?.director?.[0]?.properties?.name?.[0],
                      sourceUrl: dir.properties?.director?.[0]?.properties?.sourceUrl?.[0],
                      riskTopic: dir.properties?.director?.[0]?.properties?.topics?.[0],
                      additionalFields: {
                        'Role': dir.properties?.role?.[0],
                        'Start Date': formatDate(dir.properties?.startDate)
                      }
                    })),
                    'directors',
                    result?.properties?.directorshipOrganization?.length || 0
                  )}
  
                  {/* Assets */}
                  {renderSectionWithToggle(
                    resultId,
                    'Assets',
                    props.ownershipOwner?.map((asset, idx) => renderEntityBox({
                      key: idx,
                      name: asset.properties?.asset?.[0]?.caption,
                      sourceUrl: asset.properties?.asset?.[0]?.properties?.sourceUrl?.[0],
                      riskTopic: asset.properties?.asset?.[0]?.properties?.topics?.[0],
                      additionalFields: {
                        'Asset Type': asset.properties?.asset?.[0]?.schema,
                        'Ownership': `${asset.properties?.percentage || 0}%`,
                        'Last Seen': formatDate(asset.properties?.asset?.[0]?.last_seen)
                      }
                    })),
                    'assets',
                    result?.properties?.ownershipOwner?.length || 0
                  )}
  
                  {/* Links */}
                  {renderSectionWithToggle(
                    resultId,
                    'Links',
                    props.unknownLinkFrom?.map((link, idx) => renderEntityBox({
                      key: idx,
                      name: link.properties?.subject?.[0]?.caption,
                      sourceUrl: link.properties?.subject?.[0]?.properties?.sourceUrl?.[0],
                      riskTopic: link.properties?.subject?.[0]?.properties?.topics?.[0],
                      country: link.properties?.subject?.[0]?.properties?.country?.[0],
                      additionalFields: {
                        'Type': link.properties?.subject?.[0]?.schema,
                        'Last Seen': formatDate(link.properties?.subject?.[0]?.last_seen)
                      }
                    })),
                    'links',
                    result?.properties?.unknownLinkFrom?.length || 0
                  )}
  
                  {/* Associates */}
                  {renderSectionWithToggle(
                    resultId,
                    'Associates',
                    props.associations?.map((assoc, idx) => renderEntityBox({
                      key: idx,
                      name: assoc.properties?.person?.[0]?.caption,
                      sourceUrl: assoc.properties?.sourceUrl?.[0],
                      riskTopic: assoc.properties?.person?.[0]?.properties?.topics?.[0],
                      country: assoc.properties?.person?.[0]?.properties?.country?.[0],
                      relationship: assoc.properties?.relationship?.map(r => 
                        r.charAt(0).toUpperCase() + r.slice(1)
                      ).join(', ')
                    })),
                    'associates',
                    result?.properties?.associations?.length || 0
                  )}
  
                  {/* Family */}
                  {renderSectionWithToggle(
                    resultId,
                    'Family Members',
                    props.familyPerson?.map((family, idx) => renderEntityBox({
                      key: idx,
                      name: family.properties?.relative?.[0]?.caption,
                      sourceUrl: family.properties?.relative?.[0]?.properties?.sourceUrl?.[0],
                      riskTopic: family.properties?.relative?.[0]?.properties?.topics?.[0],
                      country: family.properties?.relative?.[0]?.properties?.country?.[0],
                      relationship: family.properties?.relationship?.map(r => 
                        r.charAt(0).toUpperCase() + r.slice(1)
                      ).join(', ')
                    })),
                    'family',
                    result?.properties?.familyPerson?.length || 0
                  )}
  
                  {/* Media Results */}
                  {renderSectionWithToggle(
                    resultId,
                    'Media Results',
                    <div>
                      {result.mediaResults?.llm_response && (
                        <div 
                          className="prose max-w-none mb-4"
                          dangerouslySetInnerHTML={{ 
                            __html: formatLLMResponse(result.mediaResults.llm_response) 
                          }}
                        />
                      )}
                      {result.mediaResults?.sources && (
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                          {result.mediaResults.sources.slice(0, 6).map((source, idx) => (
                            <div 
                              key={idx}
                              className="p-4 bg-gray-50 rounded-lg hover:bg-gray-100"
                            >
                              <a 
                                href={source.link}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-sm font-medium text-blue-600 hover:text-blue-800"
                              >
                                {source.title}
                              </a>
                              <p className="text-xs text-gray-600 mt-2">{source.snippet}</p>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>,
                    'media',
                    result.mediaResults?.sources?.length || 0
                  )}
  
                  {/* Comments */}
                  <div className="mt-6">
                    <h4 className="text-md font-medium text-gray-900 mb-2">Investigator's Comments</h4>
                    <textarea
                      value={comments[resultId] || ''}
                      onChange={(e) => handleCommentsChange(resultId, e.target.value)}
                      className="w-full h-32 p-2 border rounded-md"
                      placeholder="Add your comments here..."
                    />
                  </div>
                </div>
              )}
            </div>
          );
        })}
  
        {/* Conclusion */}
        <div className="mt-8 border rounded-lg bg-white shadow-sm p-4">
          <h4 className="text-lg font-medium text-gray-900 mb-4">Investigation Conclusion</h4>
          <textarea
            value={localStorage.getItem('investigatorConclusion') || ''}
            onChange={(e) => localStorage.setItem('investigatorConclusion', e.target.value)}
            className="w-full h-48 p-2 border rounded-md"
            placeholder="Enter your final conclusion here..."
          />
        </div>
  
        {/* Print Button - make it responsive */}
        <div className="flex justify-center mt-8 print:hidden px-4 sm:px-0">
          <button
            onClick={() => window.print()}
            className="w-full max-w-md flex items-center justify-center px-4 py-2 bg-gray-100 hover:bg-gray-200 text-gray-600 rounded-md transition-colors"
          >
            <Printer className="w-4 h-4 mr-2" />
            <span>Print Report</span>
          </button>
        </div>
      </div>
    );
  };

  export default SearchResults;