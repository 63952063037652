import { COUNTRY_MAPPING } from '../constants/countries';

const formatDate = (dateStr) => {
    if (!dateStr) return '';
    try {
      if (Array.isArray(dateStr)) {
        dateStr = dateStr[0];
      }
      return new Date(dateStr).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      });
    } catch (e) {
      return dateStr;
    }
  };
  
const formatName = (name) => {
  if (!name) return '';
  return name.split(' ').map(word => 
    word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
  ).join(' ');
};
  
const getResultTitle = (result) => {
  if (!result) return 'Unnamed Result';
  
  // For no_sanctions results, use caption first, then fall back to name
  if (result.id === 'no_sanctions') {
    const displayText = result.caption || result.name;
    return displayText ? displayText.split(' ').map(word => 
      word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    ).join(' ') : 'Unnamed Result';
  }
  
  // For regular results, use caption or name
  return result.caption || result.name || 'Unnamed Result';
};


const formatLLMResponse = (text) => {
if (!text) return '';
const paragraphs = text.split('\n');
return paragraphs.map((paragraph, index) => {
    if (!paragraph) return '';
    
    const withLinks = paragraph.replace(
    /\[([^\]]+)\]\(([^)]+)\)/g,
    '<a href="$2" target="_blank" rel="noopener noreferrer" class="text-blue-600 hover:text-blue-800">$1</a>'
    );

    for (let i = 6; i >= 1; i--) {
    const headerMatch = withLinks.match(new RegExp(`^#{${i}}\\s+(.+)$`));
    if (headerMatch) {
        return `<h${i} class="text-${i === 1 ? '2xl' : i === 2 ? 'xl' : 'lg'} font-bold my-3">${headerMatch[1]}</h${i}>`;
    }
    }

    const withBold = withLinks.replace(
    /\*\*([^*]+)\*\*/g,
    '<strong>$1</strong>'
    );

    return `<p class="mb-3">${withBold}</p>`;
}).join('');
};


const getRiskTag = (topic) => {
const riskTagMapping = {
    "crime": "Crime",
    "crime.fraud": "Fraud",
    "crime.cyber": "Cybercrime",
    "crime.fin": "Financial crime",
    "crime.env": "Environmental violations",
    "crime.theft": "Theft",
    "crime.war": "War crimes",
    "crime.boss": "Criminal leadership",
    "crime.terror": "Terrorism",
    "crime.traffick": "Trafficking",
    "crime.traffick.drug": "Drug trafficking",
    "crime.traffick.human": "Human trafficking",
    "wanted": "Wanted",
    "corp.offshore": "Offshore",
    "corp.shell": "Shell company",
    "corp.public": "Public listed company",
    "corp.disqual": "Disqualified",
    "gov": "Government",
    "gov.national": "National government",
    "gov.state": "State government",
    "gov.muni": "Municipal government",
    "gov.soe": "State-owned enterprise",
    "gov.igo": "Intergovernmental organization",
    "gov.head": "Head of government or state",
    "gov.admin": "Civil service",
    "gov.executive": "Executive branch",
    "gov.legislative": "Legislative branch",
    "gov.judicial": "Judicial branch",
    "gov.security": "Security services",
    "gov.financial": "Central banking",
    "fin": "Financial services",
    "fin.bank": "Bank",
    "fin.fund": "Fund",
    "fin.advisor": "Financial advisor",
    "reg.action": "Regulator action",
    "reg.warn": "Regulator warning",
    "role.pep": "Politician",
    "role.pol": "Non-PEP",
    "role.rca": "Close Associate",
    "role.judge": "Judge",
    "role.civil": "Civil servant",
    "role.diplo": "Diplomat",
    "role.lawyer": "Lawyer",
    "role.acct": "Accountant",
    "role.spy": "Intelligence Officer",
    "role.oligarch": "Oligarch",
    "role.journo": "Journalist",
    "role.act": "Activist",
    "role.lobby": "Lobbyist",
    "pol.party": "Political party",
    "pol.union": "Union",
    "rel": "Religion",
    "mil": "Military",
    "asset.frozen": "Frozen asset",
    "sanction": "Sanctioned entity",
    "sanction.linked": "Sanction-linked entity",
    "sanction.counter": "Counter-sanctioned entity",
    "export.control": "Export controlled",
    "export.risk": "Trade risk",
    "debarment": "Debarred entity",
    "poi": "Person of interest"
};
return riskTagMapping[topic] || topic;
};


const renderLinks = (links, caption) => {
if (!links?.length) return caption;
return (
    <a 
    href={links[0]} 
    target="_blank" 
    rel="noopener noreferrer" 
    className="text-blue-600 hover:text-blue-800"
    >
    {caption}
    </a>
);
};

  // Add this helper function at the top of the file or in a utils file
const getCountryCode = (fullCountry) => {
    return Object.entries(COUNTRY_MAPPING).find(([code, name]) => 
      name === fullCountry
    )?.[0] || 'us';
  };

// Move these functions from App.js to this new file
export { formatDate, formatName, getRiskTag, getResultTitle, formatLLMResponse, renderLinks, getCountryCode };