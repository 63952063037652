import { Bell } from 'lucide-react';

const MonitorButton = ({ onClick }) => {
  return (
    <button
      onClick={onClick}
      className="px-3 py-1 text-sm font-medium text-gray-600 hover:text-gray-900 bg-white border rounded-full shadow-sm hover:shadow transition-all"
    >
      <Bell className="w-4 h-4 inline-block sm:mr-1" />
      <span className="hidden sm:inline">Monitor</span>
    </button>
  );
};

export default MonitorButton;