import React, { useState, useEffect } from 'react';
import { getFirestore, collection, query, where, orderBy, doc, getDoc, getDocs, limit, startAfter, writeBatch } from 'firebase/firestore';
import { User, Building2, ChevronRight, Bell, Clock, Trash2 } from 'lucide-react';
import { COUNTRY_MAPPING } from '../constants/countries';
import MonitorButton from './MonitorButton';
import { db } from '../config/firebase';
import { toast } from 'react-hot-toast';
import { saveMonitoredEntity } from '../services/api';
import { formatName, getResultTitle } from '../utils/formatters';
import { 
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "./ui/alert-dialog";

const MAX_MONITORED_ENTITIES = 5;

const SearchHistoryDashboard = ({ user, onHistoryItemClick, onMonitorClick }) => {
    const [searchHistory, setSearchHistory] = useState([]);
    const [loading, setLoading] = useState(true);
    const [filter, setFilter] = useState('all');

    const [lastDoc, setLastDoc] = useState(null);
    const [hasMore, setHasMore] = useState(true);
    const [isLoadingMore, setIsLoadingMore] = useState(false);
    const ITEMS_PER_PAGE = 10;
  
    // Add a function to load more items
    const loadMore = async () => {
      if (!hasMore || isLoadingMore) return;
      
      try {
        setIsLoadingMore(true);
        const db = getFirestore();
        const moreHistoryQuery = query(
          collection(db, 'users', user.uid, 'searches'),
          orderBy('timestamp', 'desc'),
          startAfter(lastDoc),
          limit(ITEMS_PER_PAGE)
        );
        
        const querySnapshot = await getDocs(moreHistoryQuery);
        const moreHistory = querySnapshot.docs.map(doc => {
          const data = doc.data();
          return {
            id: doc.id,
            timestamp: data.timestamp?.toDate?.() || data.timestamp,
            searchType: data.searchType,
            individualName: data.individualName || '',
            companyName: data.companyName || '',
            organization: data.organization || '',
            designation: data.designation || '',
            dob: data.dob || '',  // Add this
            gender: data.gender || '', // Add this
            country: data.country || '🇺🇸 United States',
            matchThreshold: data.matchThreshold || 75,
            alerts: data.alerts || 0,
            hasResults: !!data.results?.length
          };
        });
        
        setSearchHistory(prev => [...prev, ...moreHistory]);
        setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);
        setHasMore(querySnapshot.docs.length === ITEMS_PER_PAGE);
      } catch (error) {
        console.error('Error loading more history:', error);
      } finally {
        setIsLoadingMore(false);
      }
    };

    const handleHistoryItemClick = async (historyItem) => {
      try {
        // Add debug logging
        console.log('Initial historyItem:', historyItem);
        
        // Fetch the full document to get results
        const docRef = doc(db, 'users', user.uid, 'searches', historyItem.id);
        const docSnap = await getDoc(docRef);
        const fullData = docSnap.data();
        
        // Add more debug logging
        console.log('Fetched document data:', {
          timestamp: fullData.timestamp,
          timestampType: typeof fullData.timestamp,
          hasToDate: !!fullData.timestamp?.toDate
        });
        
        // Pass only what's needed
        onHistoryItemClick({
          ...historyItem,
          results: fullData.results || [],
          // Include all original search inputs
          dob: fullData.dob || '',
          gender: fullData.gender || '',
          organization: fullData.organization || '',
          designation: fullData.designation || '',
          // Convert timestamp here if it's a Firestore timestamp
          searchTimestamp: fullData.timestamp?.toDate?.() || null
        });
      } catch (error) {
        console.error('Error fetching full history item:', error);
        toast.error('Failed to load search results');
      }
    };

    useEffect(() => {

      const fetchHistory = async () => {
        try {
          const db = getFirestore();
          const q = query(
            collection(db, 'users', user.uid, 'searches'),
            orderBy('timestamp', 'desc'),
            limit(ITEMS_PER_PAGE)
          );
          
          const querySnapshot = await getDocs(q);
          const history = querySnapshot.docs.map(doc => {
            const data = doc.data();
            // Only keep metadata, not full results
            return {
              id: doc.id,
              timestamp: data.timestamp?.toDate?.() || data.timestamp,
              searchType: data.searchType,
              individualName: data.individualName || '',
              companyName: data.companyName || '',
              organization: data.organization || '',
              designation: data.designation || '',
              dob: data.dob || '',  // Add this
              gender: data.gender || '', // Add this
              country: data.country || '🇺🇸 United States',
              matchThreshold: data.matchThreshold || 75,
              alerts: data.alerts || 0,
              // Don't include full results here
              hasResults: !!data.results?.length
            };
          });
          
          setSearchHistory(history);
          setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);
          setHasMore(querySnapshot.docs.length === ITEMS_PER_PAGE);
          setLoading(false);
        } catch (error) {
          console.error('Error fetching search history:', error);
          setLoading(false);
        }
      };

      if (user?.uid) {
        fetchHistory();
      }
    }, [user]);
  
    const filteredHistory = searchHistory.filter(item => {
      if (filter === 'all') return true;
      return item.searchType === filter;
    });
  
    const handleMonitorFromHistory = async (searchHistoryItem) => {
      try {
        // Check for monitoring limit
        const monitoringRef = collection(db, 'users', user.uid, 'monitoring');
        const monitoringSnapshot = await getDocs(monitoringRef);
        
        if (monitoringSnapshot.size >= MAX_MONITORED_ENTITIES) {
          toast.error(`Maximum limit of ${MAX_MONITORED_ENTITIES} monitored entities reached`);
          return;
        }

        // Show immediate "Adding..." toast
        const toastId = toast.loading("Adding to monitoring...");
    
        // Get the full document to ensure we have results
        const docRef = doc(db, 'users', user.uid, 'searches', searchHistoryItem.id);
        const docSnap = await getDoc(docRef);
        const fullData = docSnap.data();
    
        if (!fullData?.results) {
          toast.error('No results found in search history');
          return;
        }
    
        // Filter out any undefined values from results
        const sanitizedResults = fullData.results.filter(result => result !== undefined);
        
        const monitoringData = {
          userId: user.uid,
          searchType: searchHistoryItem.searchType,
          individualName: searchHistoryItem.individualName || null,
          companyName: searchHistoryItem.companyName || null,
          organization: searchHistoryItem.organization || null,
          designation: searchHistoryItem.designation || null,
          country: searchHistoryItem.country || "🇺🇸 United States",
          matchThreshold: searchHistoryItem.matchThreshold || 0.75,
          frequency: 'daily',
          timestamp: new Date(),
          lastChecked: searchHistoryItem.timestamp,
          alerts: 0,
          status: 'active',
          results: sanitizedResults,
          rejectedResults: [] // Initialize empty array for rejected results
        };
    
        // console.log('Saving monitoring data from history:', monitoringData);
        
        await saveMonitoredEntity(user.uid, monitoringData);
    
        // Update to success when complete
        toast.success("Entity added to monitoring", { id: toastId });
        
      } catch (error) {
        console.error('Error setting up monitoring from history:', error);
        toast.error('Failed to set up monitoring');
      }
    };
  
    const handleClearHistory = async () => {
      try {
        const toastId = toast.loading('Clearing search history...');
        const batch = writeBatch(db);
        
        // Get all search history documents
        const searchHistoryRef = collection(db, 'users', user.uid, 'searches');
        const searchHistoryDocs = await getDocs(searchHistoryRef);
        
        // Add all documents to batch delete
        searchHistoryDocs.forEach(doc => {
          batch.delete(doc.ref);
        });
    
        // Commit the batch
        await batch.commit();
        
        // Update local state after successful deletion
        setSearchHistory([]);
        toast.success('Search history cleared', { id: toastId });
      } catch (error) {
        console.error('Error clearing history:', error);
        toast.error('Failed to clear search history');
      }
    };

    return (
      <div className="bg-white rounded-2xl shadow-sm border p-6">
        {/* Header with responsive spacing */}
        <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between mb-6">
          <div className="flex items-center mb-4 sm:mb-0">
            <h2 className="text-2xl font-semibold">Search History</h2>
            {searchHistory.length > 0 && (
              <AlertDialog>
                <AlertDialogTrigger asChild>
                  <button 
                    className="relative ml-2 flex items-center justify-center w-8 h-8 rounded-full bg-red-50 hover:bg-red-100 focus:outline-none focus:ring-0"
                  >
                    <Trash2 className="w-4 h-4 text-red-600" />
                  </button>
                </AlertDialogTrigger>
                <AlertDialogContent onPointerDownOutside={(e) => e.preventDefault()}>
                  <AlertDialogHeader>
                    <AlertDialogTitle>Clear Search History</AlertDialogTitle>
                    <AlertDialogDescription>
                      This will permanently delete your search history. This action cannot be undone.
                    </AlertDialogDescription>
                  </AlertDialogHeader>
                  <AlertDialogFooter>
                    <AlertDialogCancel 
                      onPointerDown={(e) => e.preventDefault()}
                      onClick={() => {
                        // Reset any state or side effects here if needed
                      }}
                    >
                      Cancel
                    </AlertDialogCancel>
                    <AlertDialogAction
                      onClick={handleClearHistory}
                      className="bg-red-600 hover:bg-red-700 focus:ring-red-600 text-white"
                    >
                      Clear History
                    </AlertDialogAction>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialog>
            )}
          </div>
          
          {/* Filter buttons - stack on mobile, row on desktop */}
          <div className="flex gap-2 overflow-x-auto sm:overflow-visible -mx-6 px-6 sm:mx-0 sm:px-0 pb-2 sm:pb-0">
            <button
              onClick={() => setFilter('all')}
              className={`flex-none px-4 py-2 rounded-lg text-sm ${
                filter === 'all' 
                  ? 'bg-gray-100 text-gray-900'
                  : 'text-gray-600 hover:bg-gray-50'
              }`}
            >
              All
            </button>
            <button
              onClick={() => setFilter('individual')}
              className={`flex-none px-4 py-2 rounded-lg text-sm ${
                filter === 'individual'
                  ? 'bg-gray-100 text-gray-900'
                  : 'text-gray-600 hover:bg-gray-50'
              }`}
            >
              Individuals
            </button>
            <button
              onClick={() => setFilter('company')}
              className={`flex-none px-4 py-2 rounded-lg text-sm ${
                filter === 'company'
                  ? 'bg-gray-100 text-gray-900'
                  : 'text-gray-600 hover:bg-gray-50'
              }`}
            >
              Companies
            </button>
          </div>
        </div>
  
        {/* History Items */}
        <div className="-mx-6 sm:mx-0">
          <div className="space-y-3">
            {filteredHistory.map((search) => {
              // console.log('Search History Item:', {
              //   searchType: search.searchType,
              //   name: search.individualName || search.companyName,
              //   hasResults: search.hasResults
              // });
              
              return (
                <div
                  key={search.id}
                  onClick={() => handleHistoryItemClick(search)}
                  className="w-full flex items-center p-4 sm:p-4 hover:bg-gray-50 transition-colors text-left border-b sm:border sm:rounded-xl cursor-pointer"
                >
                  {/* Icon */}
                  <div className="flex-shrink-0">
                    {search.searchType === 'individual' ? (
                      <User className="w-10 h-10 text-gray-400" />
                    ) : (
                      <Building2 className="w-10 h-10 text-gray-400" />
                    )}
                  </div>
  
                  {/* Content */}
                  <div className="ml-4 flex-1 min-w-0">
                    <h3 className="text-base sm:text-lg font-medium text-gray-900 truncate">
                      {search.searchType === 'individual' ? formatName(search.individualName) : formatName(search.companyName)}
                    </h3>
                    {/* Mobile layout */}
                    <div className="flex flex-col gap-1 sm:hidden mt-1 text-sm text-gray-500">
                      <time className="truncate">
                        {search.timestamp instanceof Date && search.timestamp.toLocaleDateString('en-US', {
                          month: 'long',
                          day: 'numeric',
                          year: 'numeric'
                        })}
                      </time>
                      <span className="px-2 py-1 bg-gray-100 rounded-lg w-fit">
                        {search.country}
                      </span>
                      {search.alerts > 0 && (
                        <span className="flex items-center text-red-600 w-fit">
                          ⚠️ {search.alerts} {search.alerts === 1 ? 'alert' : 'alerts'}
                        </span>
                      )}
                    </div>
                    {/* Desktop layout */}
                    <div className="hidden sm:flex flex-wrap items-center gap-2 mt-1 text-sm text-gray-500">
                      <time className="truncate">
                        {search.timestamp instanceof Date && search.timestamp.toLocaleDateString('en-US', {
                          month: 'long',
                          day: 'numeric',
                          year: 'numeric'
                        })}
                        <span className="inline">
                          {' at ' + search.timestamp.toLocaleTimeString('en-US', {
                            hour: 'numeric',
                            minute: 'numeric',
                            hour12: true
                          })}
                        </span>
                      </time>
                      <span className="px-2 py-1 bg-gray-100 rounded-lg truncate">
                        {search.country}
                      </span>
                      {search.alerts > 0 && (
                        <span className="flex items-center text-red-600">
                          ⚠️ {search.alerts} {search.alerts === 1 ? 'alert' : 'alerts'}
                        </span>
                      )}
                    </div>
                  </div>
  
                  {/* Monitor Button */}
                  <MonitorButton onClick={(e) => {
                    e.stopPropagation();
                    handleMonitorFromHistory(search);
                  }} />
  
                  {/* Clickable Chevron */}
                  <div className="flex-shrink-0 ml-4">
                    <ChevronRight className="h-5 w-5 text-gray-400" />
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        {/* Add Load More button here */}
        {!loading && filteredHistory.length > 0 && hasMore && (
          <div className="flex justify-center py-4">
            <button
              onClick={loadMore}
              disabled={isLoadingMore}
              className="px-4 py-2 bg-gray-100 text-gray-700 rounded-md hover:bg-gray-200 disabled:opacity-50"
            >
              {isLoadingMore ? 'Loading...' : 'Load More'}
            </button>
          </div>
        )}

        {/* Loading and Empty States remain the same */}
        {loading && (
          <div className="space-y-4">
            {[1, 2, 3].map((i) => (
              <div key={i} className="animate-pulse">
                <div className="flex items-center">
                  <div className="rounded-full bg-gray-200 h-10 w-10"></div>
                  <div className="ml-4 flex-1">
                    <div className="h-4 bg-gray-200 rounded w-3/4"></div>
                    <div className="mt-2 h-3 bg-gray-200 rounded w-1/2"></div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
  
        {!loading && filteredHistory.length === 0 && (
          <div className="text-center py-8">
            <Clock className="mx-auto h-12 w-12 text-gray-400" />
            <h3 className="mt-2 text-sm font-semibold text-gray-900">No search history</h3>
            <p className="mt-1 text-sm text-gray-500">
              Your past searches will appear here
            </p>
          </div>
        )}
      </div>
    );
  };

  export default SearchHistoryDashboard;