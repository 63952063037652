import React, { useState, useEffect, useRef } from 'react';

const TypewriterText = ({ text, onComplete, children, className = 'text-2xl font-semibold' }) => {
  const [displayText, setDisplayText] = useState('');
  const [isComplete, setIsComplete] = useState(false);
  const intervalRef = useRef(null);
  
  useEffect(() => {
    let currentIndex = 0;
    intervalRef.current = setInterval(() => {
      if (currentIndex <= text.length) {
        setDisplayText(text.substring(0, currentIndex));
        currentIndex++;
      } else {
        clearInterval(intervalRef.current);
        setIsComplete(true);
        if (onComplete) onComplete();
      }
    }, 25);
    
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, []); // Only run on mount
  
  return (
    <>
      <h2 className={`text-center ${className}`}>
        {displayText}
        <span className={`inline-block animate-pulse ${displayText.length < text.length ? 'ml-1' : 'hidden'}`}>|</span>
      </h2>
      <div className={`transition-opacity duration-500 ${isComplete ? 'opacity-100' : 'opacity-0'}`}>
        {children}
      </div>
    </>
  );
};

export default TypewriterText;