import React, { useState, useEffect } from 'react';
import TypewriterText from './TypewriterText';
import { ArrowDown } from 'lucide-react';

const LandingAnimation = ({ onComplete, isMobile = false }) => {
  const [firstLineComplete, setFirstLineComplete] = useState(false);
  const [arrowVisible, setArrowVisible] = useState(false);

  useEffect(() => {
    if (firstLineComplete) {
      const timer = setTimeout(() => {
        setArrowVisible(true);
      }, 1800); // Adjust the delay as needed

      return () => clearTimeout(timer);
    }
  }, [firstLineComplete]);

  return (
    <div className="flex flex-col items-center text-center">
      <TypewriterText 
        text="Introducing Hound" 
        className="text-4xl font-bold text-gray-900"
        onComplete={() => setFirstLineComplete(true)}
      />
      
      {firstLineComplete && (
        <>
          <TypewriterText 
            text="Your AI assistant for continuous due diligence"
            className="text-xl text-gray-600 mt-2"
            onComplete={onComplete}
          />
          {isMobile && arrowVisible && (
            <div className="mt-4">
              <ArrowDown className="h-6 w-6 text-gray-400 animate-bounce" />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default LandingAnimation;