import React, { useState, useEffect } from 'react';
import { 
  onAuthStateChanged
} from 'firebase/auth';
import { 
  logEvent, 
  setUserProperties,
  setUserId
} from 'firebase/analytics';
import { Toaster } from 'react-hot-toast';
import LandingPage from './components/LandingPage';
import SearchApp from './components/SearchApp';
import { auth, analytics } from './config/firebase';

const App = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      console.log('Auth state changed:', user ? 'logged in' : 'logged out'); // Add this
      setUser(user);
      setLoading(false);

      // Track user authentication state changes
      logEvent(analytics, 'auth_state_changed', {
        is_authenticated: Boolean(user)
      });

      if (user) {
        setUserId(analytics, user.uid);
        setUserProperties(analytics, {
          user_type: user.email.endsWith('.edu') ? 'academic' : 'general',
          auth_method: user.providerData[0]?.providerId || 'unknown',
          account_creation_date: user.metadata.creationTime
        });
      }
    });

    return () => unsubscribe();
  }, []);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-primary-500"></div>
      </div>
    );
  }

  return user ? (
    <>
      <Toaster />
      <SearchApp 
        user={user} 
      />
    </>
  ) : (
    <LandingPage 
      onLogin={() => {
        // Handle any post-login logic if needed
      }} 
    />
  );
};

export default App;