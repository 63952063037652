import React, { useState, useEffect, useRef } from 'react';
import { MoreVertical, AlertCircle, User, Building2, Bell, ChevronRight, X, Trash2 } from 'lucide-react';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "./ui/alert-dialog";
import { 
  collection, 
  query, 
  where, 
  getDocs, 
  updateDoc,
  deleteDoc,
  doc,
  onSnapshot,
  addDoc,
  writeBatch,
  orderBy,
  startAfter,
  limit
} from 'firebase/firestore';
import { db } from '../config/firebase';
import { auth } from '../config/firebase';
import { toast } from 'react-hot-toast';
import { API_URL } from '../services/api';
import { COUNTRY_MAPPING } from '../constants/countries';
import { getResultTitle } from '../utils/formatters';

const MonitoringDashboard = ({ user, onMonitorItemClick }) => {
  const isLocalhost = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';
  const [monitoredItems, setMonitoredItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState('all');
  const [showNotifications, setShowNotifications] = useState(false);
  const [notifications, setNotifications] = useState([]);

  const [lastDoc, setLastDoc] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const ITEMS_PER_PAGE = 10;

  const fetchData = async () => {
    try {
      setLoading(true);
      
      // Update initial query to include ordering and limit
      const monitoringQuery = query(
        collection(db, 'users', user.uid, 'monitoring'),
        orderBy('timestamp', 'desc'),
        limit(ITEMS_PER_PAGE)
      );

      // Fetch monitored items
      const monitoringSnapshot = await getDocs(monitoringQuery);

      // Process monitored items
      const items = monitoringSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      const sortedItems = items.sort((a, b) => b.timestamp?.seconds - a.timestamp?.seconds);

      setMonitoredItems(sortedItems);
      setLastDoc(monitoringSnapshot.docs[monitoringSnapshot.docs.length - 1]);
      setHasMore(monitoringSnapshot.docs.length === ITEMS_PER_PAGE);

      // Fetch notifications for the panel
      const notificationsSnapshot = await getDocs(query(
        collection(db, 'users', user.uid, 'notifications'),
        where('read', '==', false)
      ));
      
      const newNotifications = notificationsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setNotifications(newNotifications);

    } catch (error) {
      console.error('Error fetching data:', error);
      toast.error('Failed to load monitoring data');
    } finally {
      setLoading(false);
    }
  };

  // Add new loadMore function
  const loadMore = async () => {
    if (!hasMore || isLoadingMore) return;
    
    try {
      setIsLoadingMore(true);
      
      const moreMonitoringQuery = query(
        collection(db, 'users', user.uid, 'monitoring'),
        orderBy('timestamp', 'desc'),
        startAfter(lastDoc),
        limit(ITEMS_PER_PAGE)
      );
      
      const querySnapshot = await getDocs(moreMonitoringQuery);
      const moreItems = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      
      // Sort new items before adding them
      const sortedMoreItems = moreItems.sort((a, b) => b.timestamp?.seconds - a.timestamp?.seconds);
      
      setMonitoredItems(prev => [...prev, ...sortedMoreItems]);
      setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);
      setHasMore(querySnapshot.docs.length === ITEMS_PER_PAGE);
    } catch (error) {
      console.error('Error loading more items:', error);
      toast.error('Failed to load more items');
    } finally {
      setIsLoadingMore(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [user.uid]);

  const handleMonitoringUpdate = async (itemId, frequency) => {
    try {
      const docRef = doc(db, 'users', user.uid, 'monitoring', itemId);
      await updateDoc(docRef, {
        frequency,
        lastUpdated: new Date()
      });
      // Refresh the list after update
      fetchData();
      toast.success('Monitoring frequency updated');
    } catch (error) {
      console.error('Error updating monitoring frequency:', error);
      toast.error('Failed to update monitoring frequency');
    }
  };

  const handleDelete = async (itemId) => {
    try {

      // Show loading toast that can be updated
      const toastId = toast.loading('Removing entity...');

      // Delete the monitored item
      const docRef = doc(db, 'users', user.uid, 'monitoring', itemId);
      
      // Get all notifications for this entity
      const notificationsQuery = query(
        collection(db, 'users', user.uid, 'notifications'),
        where('entityId', '==', itemId)
      );
      const notificationsSnapshot = await getDocs(notificationsQuery);
      
      // Create a batch using writeBatch
      const batch = writeBatch(db);
      
      // Add monitored item deletion to batch
      batch.delete(docRef);
      
      // Add all notification deletions to batch
      notificationsSnapshot.docs.forEach(notificationDoc => {
        batch.delete(notificationDoc.ref);
      });
      
      // Check if this is the last monitored item
      const remainingItems = monitoredItems.filter(item => item.id !== itemId);
      if (remainingItems.length === 0) {
        // If no items left, delete all notifications
        const allNotificationsQuery = query(
          collection(db, 'users', user.uid, 'notifications')
        );
        const allNotificationsSnapshot = await getDocs(allNotificationsQuery);
        
        allNotificationsSnapshot.docs.forEach(notificationDoc => {
          batch.delete(notificationDoc.ref);
        });
        
        // Clear all notifications in local state
        setNotifications([]);
      } else {
        // Update local notifications state to remove only notifications for this entity
        setNotifications(prevNotifications => 
          prevNotifications.filter(notification => notification.entityId !== itemId)
        );
      }
      
      // Execute all deletions in a single batch
      await batch.commit();
      
      // Remove the item from monitored items
      setMonitoredItems(remainingItems);
      
      // Update to success when complete
      toast.success('Entity removed from monitoring', { id: toastId });

    } catch (error) {
      console.error('Error deleting monitored item:', error);
      toast.error('Failed to remove entity from monitoring');
    }
  };

  const formatFrequency = (frequency) => {
    return frequency.charAt(0).toUpperCase() + frequency.slice(1);
  };

  // Menu component updated with capitalized options
  const MonitoringMenu = ({ item, onRefresh }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isTestingMonitor, setIsTestingMonitor] = useState(false);
    const menuRef = useRef(null);

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
          setIsOpen(false);
        }
      };

      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);

    const testMonitoring = async () => {
      try {
        setIsTestingMonitor(true);
        const toastId = toast.loading('Running monitoring check...');
        
        const idToken = await auth.currentUser.getIdToken();
        
        const response = await fetch(`${API_URL}/test-monitoring`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${idToken}`,
            'Content-Type': 'application/json'
          }
        });
        
        if (!response.ok) {
          const errorText = await response.text();
          console.error('Response not OK:', response.status, errorText);
          throw new Error(`Failed to test monitoring: ${response.status}`);
        }
        
        const data = await response.json();

        toast.dismiss(toastId);
        toast.success(data.result?.message || 'Monitoring check completed');
        
        if (onRefresh) onRefresh();
      } catch (error) {
        console.error('Error testing monitoring:', error);
        toast.error(`Failed to test monitoring: ${error.message}`);
      } finally {
        setIsTestingMonitor(false);
        setIsOpen(false);
      }
    };

    return (
      <div className="relative" onClick={e => e.stopPropagation()} ref={menuRef}>
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="p-1 rounded-full hover:bg-gray-100"
        >
          <MoreVertical className="w-5 h-5 text-gray-500" />
        </button>
        
        {isOpen && (
          <div className="absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10">
            <div className="py-1">
              <button
                onClick={() => {
                  handleMonitoringUpdate(item.id, 'Daily');
                  setIsOpen(false);
                }}
                className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
              >
                Monitor Daily
              </button>
              <button
                onClick={() => {
                  handleMonitoringUpdate(item.id, 'Weekly');
                  setIsOpen(false);
                }}
                className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
              >
                Monitor Weekly
              </button>
              {isLocalhost && (
                <button
                  onClick={testMonitoring}
                  disabled={isTestingMonitor}
                  className={`block w-full text-left px-4 py-2 text-sm ${
                    isTestingMonitor 
                      ? 'text-gray-400 cursor-not-allowed' 
                      : 'text-blue-600 hover:bg-gray-100'
                  }`}
                >
                  {isTestingMonitor ? 'Testing...' : 'Test Monitor Now'}
                </button>
              )}
              <button
                onClick={() => {
                  handleDelete(item.id);
                  setIsOpen(false);
                }}
                className="block w-full text-left px-4 py-2 text-sm text-red-600 hover:bg-gray-100"
              >
                Delete
              </button>
            </div>
          </div>
        )}
      </div>
    );
  };

  const filteredItems = monitoredItems.filter(item => {
    if (filter === 'all') return true;
    return item.searchType === filter;
  });

  // Add this helper function at the top level of the component
  const getDisplayCountry = (countryCode) => {
    if (!countryCode || countryCode === 'zz') {
      return '🌍 Global';
    }
    return COUNTRY_MAPPING[countryCode.toLowerCase()] || countryCode;
  };

  // Add function to mark notification as read
  const markNotificationAsRead = async (notificationId) => {
    try {
      const notificationRef = doc(db, 'users', user.uid, 'notifications', notificationId);
      await updateDoc(notificationRef, {
        read: true
      });
    } catch (error) {
      console.error('Error marking notification as read:', error);
    }
  };

  const markAllNotificationsAsReadForItem = async (entityId) => {
    try {
      // Get all unread notifications for this specific entity
      const notificationsSnapshot = await getDocs(query(
        collection(db, 'users', user.uid, 'notifications'),
        where('entityId', '==', entityId),
        where('read', '==', false)
      ));
      
      // Mark notifications as read for this entity only
      const updatePromises = notificationsSnapshot.docs.map(doc => 
        updateDoc(doc.ref, { read: true })
      );
      
      // Reset alerts count to 0 since we're marking all as read
      const monitoringRef = doc(db, 'users', user.uid, 'monitoring', entityId);
      updatePromises.push(updateDoc(monitoringRef, { alerts: 0 }));
      
      await Promise.all(updatePromises);
      fetchData();
    } catch (error) {
      console.error('Error marking notifications as read:', error);
    }
  };

  const clearAlertsForItem = async (itemId) => {
    try {
      const docRef = doc(db, 'users', user.uid, 'monitoring', itemId);
      await updateDoc(docRef, {
        alerts: 0
      });
      // Refresh the list to show updated alerts count
      fetchData();
    } catch (error) {
      console.error('Error clearing alerts:', error);
    }
  };

  const NotificationsPanel = () => {
    if (!showNotifications) return null;

    return (
      <div className="fixed inset-0 bg-black/20 backdrop-blur-sm z-50">
        <div className="absolute right-0 top-0 h-full w-full max-w-xl bg-white shadow-xl">
          {/* Header */}
          <div className="flex items-center justify-between px-6 py-4 border-b">
            <div className="flex items-center gap-3">
              <Bell className="w-5 h-5 text-gray-600" />
              <h3 className="text-lg font-semibold text-gray-900">Notifications</h3>
            </div>
            <button 
              onClick={() => setShowNotifications(false)}
              className="p-2 hover:bg-gray-100 rounded-full transition-colors"
            >
              <X className="w-5 h-5 text-gray-500" />
            </button>
          </div>

          {/* Notifications List */}
          <div className="overflow-y-auto h-[calc(100vh-73px)]">
            {notifications.length === 0 ? (
              <div className="h-full flex flex-col items-center justify-center text-center p-4">
                <Bell className="w-12 h-12 text-gray-400 mb-2" />
                <h3 className="text-sm font-medium text-gray-900">No new notifications</h3>
                <p className="mt-1 text-sm text-gray-500">
                  When there are updates to your monitored entities, they will appear here
                </p>
              </div>
            ) : (
              notifications.map((notification) => (
                <div 
                  key={notification.id}
                  onClick={() => {
                    markNotificationAsRead(notification.id);
                    if (monitoredItems) {
                      const monitoredItem = monitoredItems.find(
                        item => item.id === notification.entityId
                      );
                      if (monitoredItem) {
                        clearAlertsForItem(monitoredItem.id);
                        setShowNotifications(false);
                        onMonitorItemClick(monitoredItem);
                      }
                    }
                  }}
                  className="border-b last:border-b-0 hover:bg-gray-50 transition-colors cursor-pointer"
                >
                  <div className="px-6 py-4">
                    <div className="flex items-start gap-4">
                      <div className="flex-shrink-0 mt-1">
                        <AlertCircle className={`w-5 h-5 ${
                          notification.changes.hasChanges === false 
                            ? 'text-gray-400' 
                            : 'text-amber-500'
                        }`} />
                      </div>
                      <div className="flex-1 min-w-0">
                        <h4 className="text-sm font-medium text-gray-900">
                          Updates for {notification.entityName || monitoredItems.find(
                            item => item.id === notification.entityId
                          )?.individualName || monitoredItems.find(
                            item => item.id === notification.entityId
                          )?.companyName}
                        </h4>
                        
                        <p className="text-xs text-gray-500 mt-1">
                          {new Date(notification.timestamp.seconds * 1000).toLocaleString()}
                        </p>

                        <div className="mt-3 space-y-3">
                          {/* Sanctions Added */}
                          {notification.changes.added?.length > 0 && (
                            <div className="rounded-md bg-red-50 p-3">
                              <h5 className="text-sm font-medium text-red-800 mb-2">New Sanctions Matches:</h5>
                              <ul className="list-disc pl-4 text-sm text-red-700">
                                {notification.changes.added.map((match, idx) => (
                                  <li key={idx}>{match.name}</li>
                                ))}
                              </ul>
                            </div>
                          )}

                          {/* Sanctions Removed */}
                          {notification.changes.removed?.length > 0 && (
                            <div className="rounded-md bg-green-50 p-3">
                              <h5 className="text-sm font-medium text-green-800 mb-2">Removed Sanctions Matches:</h5>
                              <ul className="list-disc pl-4 text-sm text-green-700">
                                {notification.changes.removed.map((match, idx) => (
                                  <li key={idx}>{match.name}</li>
                                ))}
                              </ul>
                            </div>
                          )}

                          {/* Media Updates */}
                          {notification.changes.mediaUpdates?.length > 0 && 
                          notification.changes.mediaUpdates[0]?.mediaChanges?.added?.length > 0 && (
                            <div className="rounded-md bg-blue-50 p-3">
                              <h5 className="text-sm font-medium text-blue-800 mb-2">New Media Coverage:</h5>
                              <ul className="space-y-2">
                                {notification.changes.mediaUpdates[0].mediaChanges.added.map((source, idx) => (
                                  <li key={idx} className="text-sm">
                                    <a 
                                      href={source.url}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      onClick={(e) => e.stopPropagation()}
                                      className="text-blue-600 hover:text-blue-800 hover:underline"
                                    >
                                      {source.title}
                                    </a>
                                    {source.date && (
                                      <span className="text-blue-700 text-xs ml-2">
                                        ({new Date(source.date).toLocaleDateString()})
                                      </span>
                                    )}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          )}

                          {/* No Changes Message */}
                          {notification.changes.hasChanges === false && (
                            <p className="text-sm text-gray-600">
                              {notification.changes.message}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    );
  };

  const getLastCheckedDate = (item) => {
    if (!item.lastChecked?.seconds) return 'Never';
    try {
      return new Date(item.lastChecked.seconds * 1000).toLocaleDateString();
    } catch (error) {
      console.error('Error formatting lastChecked date:', error);
      return 'Date error';
    }
  };

  // Add a helper function to check for unread notifications
  const hasUnreadNotifications = (itemId) => {
    return notifications.some(notification => 
      notification.entityId === itemId && !notification.read
    );
  };

  // Add this function before the return statement
  const handleClearMonitoring = async () => {
    try {
      const toastId = toast.loading('Clearing monitored entities...');
      const batch = writeBatch(db);
      
      // Get all monitoring documents
      const monitoringRef = collection(db, 'users', user.uid, 'monitoring');
      const monitoringDocs = await getDocs(monitoringRef);
      
      // Get all notifications
      const notificationsRef = collection(db, 'users', user.uid, 'notifications');
      const notificationsDocs = await getDocs(notificationsRef);
      
      // Add all monitoring documents to batch delete
      monitoringDocs.forEach(doc => {
        batch.delete(doc.ref);
      });

      // Add all notifications to batch delete
      notificationsDocs.forEach(doc => {
        batch.delete(doc.ref);
      });

      // Commit the batch
      await batch.commit();
      
      // Update local state
      setMonitoredItems([]);
      setNotifications([]);
      toast.success('All monitored entities cleared', { id: toastId });
    } catch (error) {
      console.error('Error clearing monitoring:', error);
      toast.error('Failed to clear monitored entities');
    }
  };

  return (
    <div className="bg-white rounded-2xl shadow-sm border p-6">
      {/* Header with responsive spacing */}
      <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between mb-6">
        <div className="flex items-center mb-4 sm:mb-0">
          <h2 className="text-2xl font-semibold">Monitored Entities</h2>
          {notifications.length > 0 && (
            <button 
              onClick={() => setShowNotifications(true)}
              className="relative ml-2 flex items-center justify-center w-8 h-8 rounded-full bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
            >
              <Bell className="w-4 h-4 text-gray-600" />
              <span className="absolute -top-1 -right-1 bg-red-500 text-white text-xs rounded-full w-4 h-4 flex items-center justify-center">
                {notifications.length}
              </span>
            </button>
          )}
          {monitoredItems.length > 0 && (
            <AlertDialog>
              <AlertDialogTrigger asChild>
                <button 
                  className="relative ml-2 flex items-center justify-center w-8 h-8 rounded-full bg-red-50 hover:bg-red-100 focus:outline-none focus:ring-0"
                >
                  <Trash2 className="w-4 h-4 text-red-600" />
                </button>
              </AlertDialogTrigger>
              <AlertDialogContent onPointerDownOutside={(e) => e.preventDefault()}>
                <AlertDialogHeader>
                  <AlertDialogTitle>Clear All Monitored Entities</AlertDialogTitle>
                  <AlertDialogDescription>
                    This will permanently delete all your monitored entities and their notifications. This action cannot be undone.
                  </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                  <AlertDialogCancel 
                    onPointerDown={(e) => e.preventDefault()}
                  >
                    Cancel
                  </AlertDialogCancel>
                  <AlertDialogAction
                    onClick={handleClearMonitoring}
                    className="bg-red-600 hover:bg-red-700 focus:ring-red-600 text-white"
                  >
                    Clear All
                  </AlertDialogAction>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialog>
          )}
        </div>
        
        {/* Filter buttons - stack on mobile, row on desktop */}
        <div className="flex gap-2 overflow-x-auto sm:overflow-visible -mx-6 px-6 sm:mx-0 sm:px-0 pb-2 sm:pb-0">
          <button
            onClick={() => setFilter('all')}
            className={`flex-none px-4 py-2 rounded-lg text-sm ${
              filter === 'all' 
                ? 'bg-gray-100 text-gray-900'
                : 'text-gray-600 hover:bg-gray-50'
            }`}
          >
            All
          </button>
          <button
            onClick={() => setFilter('individual')}
            className={`flex-none px-4 py-2 rounded-lg text-sm ${
              filter === 'individual'
                ? 'bg-gray-100 text-gray-900'
                : 'text-gray-600 hover:bg-gray-50'
            }`}
          >
            Individuals
          </button>
          <button
            onClick={() => setFilter('company')}
            className={`flex-none px-4 py-2 rounded-lg text-sm ${
              filter === 'company'
                ? 'bg-gray-100 text-gray-900'
                : 'text-gray-600 hover:bg-gray-50'
            }`}
          >
            Companies
          </button>
        </div>
      </div>

      {/* Monitored Items */}
      <div className="-mx-6 sm:mx-0">
        <div className="space-y-3">
          {loading ? (
            <div className="flex justify-center items-center h-64">
              <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
            </div>
          ) : filteredItems.length === 0 ? (
            <div className="text-center py-8">
              <Bell className="mx-auto h-12 w-12 text-gray-400" />
              <h3 className="mt-2 text-sm font-semibold text-gray-900">No monitored entities yet</h3>
              <p className="mt-1 text-sm text-gray-500 max-w-[250px] mx-auto">
                Run a search and click "Monitor" to start tracking an entity
              </p>
            </div>
          ) : (
            filteredItems.map((item) => {
              
              // console.log('Monitoring Item Results:', {
              //   searchType: item.searchType,
              //   name: item.individualName || item.companyName,
              //   results: item.results.map(r => ({
              //     id: r.id,
              //     name: r.name,
              //     caption: r.caption,
              //     formattedTitle: getResultTitle(r)
              //   }))
              // });
              
              // Add debugging logs for dates
              // console.log('Monitored item dates:', {
              //   id: item.id,
              //   name: item.individualName || item.companyName,
              //   timestamp: item.timestamp?.seconds ? new Date(item.timestamp.seconds * 1000) : null,
              //   lastChecked: item.lastChecked?.seconds ? new Date(item.lastChecked.seconds * 1000) : null
              // });
              
              return (
                <div
                  key={item.id}
                  className="w-full flex items-center p-4 sm:p-4 hover:bg-gray-50 transition-colors text-left border-b sm:border sm:rounded-xl"
                >
                  {/* Icon - Now with logging to debug */}
                  <div className="flex-shrink-0">
                    {item.searchType === 'individual' ? (
                      <User className="w-10 h-10 text-gray-400" />
                    ) : (
                      <Building2 className="w-10 h-10 text-gray-400" />
                    )}
                  </div>

                  {/* Content */}
                  <div 
                    className="ml-4 flex-1 min-w-0 cursor-pointer" 
                    onClick={() => {
                      markAllNotificationsAsReadForItem(item.id);
                      clearAlertsForItem(item.id);
                      onMonitorItemClick(item);
                    }}
                  >
                    <div className="flex items-center space-x-2">
                      <h3 className="text-base sm:text-lg font-medium text-gray-900 truncate">
                        {item.searchType === 'individual' ? item.individualName : item.companyName}
                      </h3>
                      {hasUnreadNotifications(item.id) && (
                        <AlertCircle className="w-5 h-5 text-amber-500" />
                      )}
                    </div>
                    <div className="flex flex-wrap items-center gap-2 mt-1 text-sm text-gray-500">
                      <span>Frequency: {formatFrequency(item.frequency)}</span>
                      <span>Last checked: {getLastCheckedDate(item)}</span>
                      <span className="px-2 py-1 bg-gray-100 rounded-lg truncate">
                        {getDisplayCountry(item.country)}
                      </span>
                      {item.alerts > 0 && (
                        <span className="flex items-center text-red-600">
                          ⚠️ {item.alerts} alerts
                        </span>
                      )}
                    </div>
                  </div>

                  {/* Menu and Chevron */}
                  <MonitoringMenu 
                    item={item} 
                    onRefresh={fetchData}
                  />
                  <div 
                    className="cursor-pointer"
                    onClick={() => {
                      markAllNotificationsAsReadForItem(item.id);
                      clearAlertsForItem(item.id);
                      onMonitorItemClick(item);
                    }}
                  >
                    <ChevronRight className="h-5 w-5 text-gray-400 flex-shrink-0 ml-4" />
                  </div>
                </div>
              );
            })
          )}
        </div>
      </div>

      {/* Add Load More button */}
      {!loading && filteredItems.length > 0 && hasMore && (
        <div className="flex justify-center py-4">
          <button
            onClick={loadMore}
            disabled={isLoadingMore}
            className="px-4 py-2 bg-gray-100 text-gray-700 rounded-md hover:bg-gray-200 disabled:opacity-50"
          >
            {isLoadingMore ? 'Loading...' : 'Load More'}
          </button>
        </div>
      )}

      {/* Add notifications panel */}
      <NotificationsPanel />
    </div>
  );

};

export default MonitoringDashboard;