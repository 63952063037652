import { useState, useRef, useEffect } from 'react';
import { User, LogOut, HelpCircle } from 'lucide-react';
import { signOut } from 'firebase/auth';
import { logEvent } from 'firebase/analytics';
import { auth, analytics } from '../config/firebase';

const UserMenu = ({ user, onSignOut }) => {
    const [isOpen, setIsOpen] = useState(false);
    const menuRef = useRef(null);
  
    // Close menu when clicking outside
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
          setIsOpen(false);
        }
      };
  
      document.addEventListener('mousedown', handleClickOutside);
      return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);
  
    const handleSignOut = async () => {
      try {
        await signOut(auth);
        logEvent(analytics, 'sign_out', { success: true });
        onSignOut();
      } catch (error) {
        logEvent(analytics, 'sign_out_error', {
          error_code: error.code,
          error_message: error.message
        });
        console.error('Error signing out:', error);
      }
    };
  
    const handleHelp = () => {
      window.location.href = 'mailto:hello@hound.li';
    };
  
    return (
      <div className="relative" ref={menuRef}>
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="flex items-center justify-center w-10 h-10 rounded-full bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
          aria-label="User menu"
        >
          <User className="w-5 h-5 text-gray-600" />
        </button>
  
        {isOpen && (
          <div className="absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-50">
            <div className="py-1">
              {/* User info section */}
              <div className="px-4 py-2 text-sm text-gray-700 border-b border-gray-200">
                <div className="font-medium truncate">{user.email}</div>
                <div className="text-xs text-gray-500">
                  Joined {new Date(user.metadata.creationTime).toLocaleDateString()}
                </div>
              </div>
              
              {/* Menu items */}
              <button
                onClick={handleHelp}
                className="w-full flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100"
              >
                <HelpCircle className="mr-2 h-4 w-4" />
                Help
              </button>
              <button
                onClick={handleSignOut}
                className="w-full flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100"
              >
                <LogOut className="mr-2 h-4 w-4" />
                Sign out
              </button>
            </div>
          </div>
        )}
      </div>
    );
  };

  export default UserMenu;