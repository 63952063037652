import { useState, useRef, useEffect } from 'react';
import { ChevronDown } from 'lucide-react';

const GenderSelect = ({ value, onChange }) => {
  const [focused, setFocused] = useState(false);
  const [activeIndex, setActiveIndex] = useState(-1);
  const dropdownRef = useRef(null);

  const options = [
    { value: 'Male', label: 'Male', emoji: '👨' },
    { value: 'Female', label: 'Female', emoji: '👩' },
    { value: 'Other', label: 'Other', emoji: '🧑' }
  ];

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setFocused(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Escape' && focused) {
        setFocused(false);
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [focused]);

  const handleKeyDown = (e) => {
    if (!focused) return;
    
    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        setActiveIndex(prev => 
          prev < options.length - 1 ? prev + 1 : 0
        );
        break;
      case 'ArrowUp':
        e.preventDefault();
        setActiveIndex(prev => 
          prev > 0 ? prev - 1 : options.length - 1
        );
        break;
      case 'Enter':
        e.preventDefault();
        if (activeIndex >= 0) {
          handleSelect(options[activeIndex].value);
        }
        break;
    }
  };

  const handleFocus = (e) => {
    e.preventDefault();
    setFocused((prev) => !prev);
    setActiveIndex(-1);
  };

  const handleSelect = (selectedValue) => {
    onChange({ target: { value: selectedValue } });
    setFocused(false);
  };

  const handleBlur = () => {
    setFocused(false);
  };

  const getSelectedEmoji = () => {
    const selectedOption = options.find(opt => opt.value === value);
    return selectedOption ? selectedOption.emoji : '';
  };

  return (
    <div 
      className="relative" 
      ref={dropdownRef}
      onKeyDown={handleKeyDown}
    >
      <div
        tabIndex={0}
        className={`input-field flex justify-between items-center h-12 px-4 py-2 bg-white border rounded-lg shadow-sm cursor-pointer ${focused ? 'text-gray-900' : 'text-gray-500'}`}
        onMouseDown={handleFocus}
        onFocus={handleFocus}
        onBlur={handleBlur}
      >
        <span>
          {value ? (
            <span className="flex items-center gap-2">
              {getSelectedEmoji()} {value}
            </span>
          ) : 'Gender'}
        </span>
        <div className="flex items-center">
          <ChevronDown size={20} className="text-gray-400" />
        </div>
      </div>

      {focused && (
        <div className="absolute z-10 bg-white shadow-md rounded w-full mt-1">
          <ul 
            className="max-h-60 overflow-auto"
            role="listbox"
          >
            {options.map((option, index) => (
              <li
                key={option.value}
                className={`px-3 py-2 cursor-pointer ${
                  index === activeIndex ? 'bg-gray-100' : 'hover:bg-gray-50'
                }`}
                onClick={() => handleSelect(option.value)}
                role="option"
                aria-selected={index === activeIndex}
              >
                <span className="flex items-center gap-2">
                  {option.emoji} {option.label}
                </span>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default GenderSelect;